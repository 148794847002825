<template>
  <div class="services__menu-wrapper">
    <div class="services__menu-line">
      <div class="menu-icon" @click="mainSideMenuShow" :class="{ 'transform-rotate_primary6': mainSideMenuShown }">
        <svg width="18" height="12" viewBox="0 0 18 12" fill="currentcolor" xmlns="http://www.w3.org/2000/svg">
          <path d="M7 12H11V10H7V12ZM0 0V2H18V0H0ZM3 7H15V5H3V7Z" />
        </svg>
      </div>
      <h3 class="menu-label">Меню</h3>
    </div>
    <div class="services__menu" v-if="mainSideMenuShown">
      <ul class="service-list">
        <li
          class="service-list__item"
          v-for="(menuItem, i) in menuItems"
          :key="i"
          @click="showSubmenu(i, menuItem), goTo(menuItem)"
          :class="{ 'service-list__item_active': activeItem === i }"
        >
          {{ menuItem.titleRu }}
          <main-downward v-if="menuItem.subItems.length > 0 && activeItem !== i" />
          <main-upward v-if="menuItem.subItems.length > 0 && activeItem === i" @click.native.stop="showSubmenu(-1)" />
          <ul class="service-list__sublist" v-if="menuItem.subItems.length > 0 && activeItem === i" >
            <li
              class="service-list__sublist-item"
              v-for="(subItem, z) in menuItem.subItems"
              :key="z"
              @click.stop="goToSub(menuItem, subItem)"
            >
              {{ subItem.titleRu }}
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

export default {
  props: {
  },
  data() {
    return {
      activeItem: -1,
      mainSideMenuShown: true,
      width: document.documentElement.clientWidth,
    };
  },
  mounted() {
    window.addEventListener('resize', this.getDimensions);
  },
  unmounted() {
    window.removeEventListener('resize', this.getDimensions);
  },
  watch: {
    width(newValue) {
      if (newValue > 992) {
        this.mainSideMenuShown = true;
      } else {
        this.mainSideMenuShown = false;
      }
    }
  },
  computed: {
    ...mapGetters(["menuItems"]),
  },
  methods: {
    ...mapMutations(["MUT_setActiveMenuItem", "MUT_setActiveSubmenuItem"]),

    getDimensions() {
      this.width = document.documentElement.clientWidth;
    },
    showSubmenu(i, item) {
      this.activeItem = i;
    },
    goTo(item) {
      if (this.$route.name != item.name) {
          this.$router.push({ name: `${item.name}`});
          this.MUT_setActiveMenuItem(item);
        }
    },
    goToSub(item, subitem) {
      if (this.$route.name != subitem.name) {
        this.$router.push({ name: `${subitem.name}`});
        this.MUT_setActiveSubmenuItem(subitem);
      }
    },
    mainSideMenuShow() {
      if (this.width < 993) {
        this.mainSideMenuShown = !this.mainSideMenuShown;
      }
    },
  },
};
</script>

<style scoped>
</style>
